<template>
  <div class="chart-diagram">
    <div class="header">
      <div class="title">
        <h3>{{ this.title || "Report" }}</h3>
        <div class="subline">{{ isLoading ? 'Loading data ... ' : subtitle }}</div>
      </div>
      <slot name="header-actions">
        <button :class="'button tab' + (visibleDateRange == 'week' ? ' active' : '')" @click="setVisibleDateRange('week')">Woche</button>
        <button :class="'button tab' + (visibleDateRange == 'month' ? ' active' : '')" @click="setVisibleDateRange('month')">Monat</button>
        <button :class="'button tab' + (visibleDateRange == 'year' ? ' active' : '')" @click="setVisibleDateRange('year')">Jahr</button>
        <button :class="'button tab' + (visibleDateRange == 'max' ? ' active' : '')" @click="setVisibleDateRange('max')">Max</button>
      </slot>
      <button v-show="isZoomEnabled" :class="'ghost button rounded short' + (isZoomedIn ? ' active' : '')" @click="toggleZoom">Zoom</button>
    </div>
    <div class="chart-wrapper">
      <Line v-if="false" />
      <Bar id="chart" ref="chartRef" :data="chartData" :options="chartOptions" />
      <transition name="fade">
        <div class="chart-gradient" v-show="isZoomedIn"></div>
      </transition>
    </div>
    <div class="no-data" v-show="!hasData">Keine Daten vorhanden</div>
    <div class="footer">
      <div class="details">
      </div>
    </div>
  </div>
</template>

<script>

import {
  Chart as ChartJS,
  registerables
} from 'chart.js'
import { Line, Bar } from 'vue-chartjs'

import UiMixin from '../mixins/UiMixin.vue';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';

ChartJS.register(...registerables);
ChartJS.register(zoomPlugin);
ChartJS.register(annotationPlugin);

const defaultLegendClickHandler = ChartJS.defaults.plugins.legend.onClick;

export default {
  name: "ChartDiagram",
  mixins: [UiMixin],
  // eslint-disable-next-line vue/require-prop-types
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Bar, Line },
  props: {
    chartData: {
      type: Object
    },
    zoomRange: {
      type: Object
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    showChartJSLegend: {
      type: Boolean,
      default: false
    },
    legendOnClickHandler: {
      type: Function
    },
  },
  data: function () {
    return {
      isZoomedIn: false,
      visibleDateRange: "max",
      visibleDateRangeMin: null,
      visibleDateRangeMax: null,
    }
  },
  methods: {
    setVisibleDateRange(range) {
      this.visibleDateRange = range
      switch (range) {
        case "max":
          this.visibleDateRangeMin = null
          this.visibleDateRangeMax = null
          break
        default:
          this.visibleDateRangeMin = moment().subtract(1, range).toDate()
          this.visibleDateRangeMax = new Date()
      }
    },
    toggleZoom() {
      this.isZoomedIn = !this.isZoomedIn
      this.updateZoom(this.zoomRange)
    },
    updateZoom(zoomRange) {
      let chart = this.$refs.chartRef.chart
      if (!chart) return
      if (this.isZoomedIn) {
        chart.zoomScale("y", zoomRange, "zoom")
      } else {
        chart.resetZoom()
      }
    },
    legendOnClick(e, legendItem, legend) {
      if (this.legendOnClickHandler) {
        this.legendOnClickHandler(e, legendItem, legend)
        return
      } else {
        defaultLegendClickHandler(e, legendItem, legend)
      }
    },
  },
  computed: {
    isZoomEnabled() {
      return this.zoomRange?.max
    },
    hasData() {
      return this.chartData?.datasets?.length > 0
    },
    chartOptions() {
      let opts = {
        animation: false,
        transitions: {
          resize: {
            animation: {
              duration: 0
            }
          }
        },
        interaction: {
          mode: 'nearest'
        },
        color: "#1682F3", //"rgba(22, 130, 243, 0.3)",
        backgroundColor: "#1682F3", //"rgba(22, 130, 243, 0.3)",
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 0,
        },
        plugins: {
          colors: {
            enabled: false,
          },
          title: {
            display: false,
            text: "",
            padding: {
              top: 10,
              bottom: 30,
            },
            align: "start",
          },
          legend: {
            display: this.showChartJSLegend,
            position: "bottom",
            align: "center",
            labels: {
              font: {
                size: 12,
                weight: "regular",
              },
              boxWidth: 13,
              boxHeight: 13,
            },
            onClick: this.legendOnClick
          },
          tooltip: {
            padding: 20,
            boxPadding: 10,
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: false,
              },
              pinch: {
                enabled: false
              },
              mode: 'y',
              //overScaleMode: 'y',
            },
            pan: {
              enabled: false,
            }
          },
/*
          14.09 18:34 - Einladung zu Framework
          - Framework 2.0 ging raus am 7.11. 10:11
          - Framework 2.1 am 11.12. 18:03h
          - Wie passt das zu den Expertenmeetings? 12.12.22, 24.02.23, 13.04.23, 19.09.23
*/
          annotation: {
                annotations: {
                    event1: {
                        type: 'line',
                        xMin: new Date('2023-09-14'), // Date of the event
                        xMax: new Date('2023-09-14'), // Same date for vertical line
                        borderColor: 'red',
                        borderWidth: 1,
                        label: {
                            content: 'NL: Einladug zu Framework',
                            enabled: true,
                            position: 'top'
                        }
                    },
                    event2: {
                      type: 'line',
                        xMin: new Date('2023-11-07'), // Date of the event
                        xMax: new Date('2023-11-07'), // Same date for vertical line
                        borderColor: 'red',
                        borderWidth: 1,
                        label: {
                            content: 'NL: Framework 2.0',
                            enabled: true,
                            position: 'top', // Position the label at the end of the line
                            yAdjust: -10, // Adjust vertically to position above the line
                            backgroundColor: 'rgba(255, 99, 132, 0.2)', // Optional background color
                            font: {
                                size: 12 // Font size
                            }
                        }
                    },
                    event3: {
                      type: 'line',
                        xMin: new Date('2023-11-12'), // Date of the event
                        xMax: new Date('2023-11-12'), // Same date for vertical line
                        borderColor: 'red',
                        borderWidth: 1,
                        label: {
                            content: 'NL: Framework 2.1',
                            enabled: true,
                            position: 'top'
                        }
                    },
                    // Add more events as needed
                }
            }
        },
        scales: {
          x: {
            type: 'time',
            stacked: true,
            color: "#aeb4cb",
            stepSize: 4,
            time: {
                    // Time settings (if applicable)
                    unit: 'month', // 'minute', 'hour', 'day', 'month', etc.
                    displayFormats: {
                        day: 'MMM'
                    }
                },
                ticks: {
                    // Customization of tick marks
                    callback: (value/*, index, values*/) => {
                        // This function allows you to format the value of each tick
                        let date = new Date(value);
                        const monthName = date.toLocaleDateString('de-DE', { month: 'short' }); // Gets full month name in German
                        const year = date.toLocaleDateString('de-DE', { year: '2-digit' }); // Gets last two digits of the year
                        const formattedDate = `${monthName} '${year}`;

                        return formattedDate;
                    }
                },

            // ticks: {
            //   color: "#aeb4cb",
            //   fontSize: 10,
            //   // callback: function(value) {
            //   //   return `${value} CW`
            //   // }
            // },
            grid: {
              borderColor: "#edeef3",
              display: false,
            },
          },
        }
      }

      if (this.visibleDateRangeMin) {
        opts.scales.x.min = this.visibleDateRangeMin
      }
      if (this.visibleDateRangeMax) {
        opts.scales.x.max = this.visibleDateRangeMax
      }
      return opts

    },
    isLoading() {
      return this.$store.state.ui.isFetchingChartData
    }
  },
  watch: {
    zoomRange(newRange) {
      this.updateZoom(newRange)
    },
  }
}
</script>

<style scoped>
.chart-diagram {
  /* height: 470px; */
  width: 100%;
}
h3 {
  margin: 0 0 10px;
  text-align: left;
  font-size: 18px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.subline {
  text-align: left;
  color: var(--c-deep-grey);
  font-size: 12px;
}
.no-data {
  color: var(--c-deep-grey);
  font-size: 12px;
  text-align: center;
  padding-bottom: 100px;
  display: flex;
  height: calc(100% - 100px);
  /* padding-left: 160px; */
  justify-content: center;
  align-items: center;
}
.details {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  justify-content: center;
  margin-top: 7px;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 20px;
  width: 100%;
}
.title {
  flex-grow: 1;
}
.detail-label {
  font-size: 12px;
  margin: 0 20px;
  position: relative;
  text-align: left;
  /* white-space: nowrap; */
  /* max-width: 100px; */
}
.detail-label:last-child {
  margin: 0;
}
.detail-label .legend-icon {
  color: var(--dot-color);
  transform: translateY(2px);
  margin-right: 4px;
}
.detail-label:first-child {
  margin-left: 0;
}
.difference {
  font-size: 12px;
  white-space: nowrap;
  margin-top: 4px;
}
.difference-value {
  margin: 0 0 0 10px;
}
.chart-gradient {
  height: 65px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);;
  position: absolute;
  top: 0;
  left: 0;
}
#chart {
  position: absolute;
  /* margin-bottom: 40px; */
}
.chart-wrapper {
  position: relative;
  min-height: 300px;
  max-height: 300px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

</style>
